<template>
  <div>
    <v-card class="text-center pa-1">
      <v-card-title class="justify-center display-1 mb-2">Uusi salasana</v-card-title>

      <!-- reset form -->
      <v-card-text>
        <v-form ref="form" @submit.prevent="submit">
          <v-text-field v-model="password" outlined type="password" label="Salasana"></v-text-field>
          <v-text-field
            outlined
            v-model="passwordConfirm"
            type="password"
            label="Toista salasana"
          ></v-text-field>

          <v-btn block x-large color="primary" @click.prevent="reset">Uusi salasana</v-btn>
        </v-form>
      </v-card-text>
    </v-card>

    <div class="text-center mt-6">
      <router-link to="/login"> Takaisin kirjautumiseen </router-link>
    </div>
  </div>
</template>

<script>
import mixins from "../../mixins/mixins";
import axiosMethods from "../../mixins/axios";
import { apiAgent } from "../../services/apiAgent";

export default {
  title: "Salasanan uusiminen",

  mixins: [mixins],

  data() {
    return {
      passwordConfirm: "",
      password: "",
    };
  },

  methods: {
    async reset() {
      try {
        await apiAgent({
          method: "patch",
          url: "/api/v1/user/resetPassword/" + this.$route.params.token,
          data: {
            password: this.password,
            passwordConfirm: this.passwordConfirm,
          },
        });
        this.$router.push("/login");
        this.showPopup("Salasana päivitetty", "success");
      } catch (err) {
        let error = await axiosMethods.validateRequest(err);
        this.showPopup(error, "error");
      }
    },
  },
};
</script>

<style scoped></style>
